import API from './data.api.ts';
import EventEmitter from './helpers.EventEmitter.ts';

function alert(...args: any) {
    import('o365-vue-services').then(services => {
        services.alert(...args);
    });
}

export interface IProcedureOptions {
    id: string;
    procedureName: string;
    useAlert?: boolean;
    useTransaction?: boolean;
    timeout?: number;
    useStreamRequest?: boolean;
}

interface IProcedureStateStorage {
    isLoading: boolean,
    isLoaded: boolean,
    executed: boolean,
    results: any
    error: any
}

type EventTypes = keyof typeof events;

const procedureStore = new Map<string, Procedure>();

const events = {
    BeforeExecute: 'BeforeExecute',
    AfterExecute: 'AfterExecute',
    Success: 'Success',
    Error: 'Error',
    ChunkLoaded: 'ChunkLoaded'
} as const

/** 
 * Get or create a procedure with the provided options. 
 * Returns raw, non-reactive Procedure instance.  
 * For reactive Procedure use the getOrCreateProcedure function from o365.vue.ts
 */
export function getOrCreateProcedure<T extends object = any>(pOptions: IProcedureOptions): Procedure<T> {
    if (!procedureStore.has(pOptions.id)) {
        new Procedure(pOptions);
    }
    return procedureStore.get(pOptions.id)!;
}


export default class Procedure<T extends object = any> {
    private readonly eventHandler = new EventEmitter();

    private _activeAbortController?: AbortController;

    public readonly id: string;
    public readonly procedureName: string;
    public readonly useAlert: boolean;
    public readonly useTransaction: boolean;
    public readonly timeout: number;
    public readonly useStreamRequest: boolean;

    public readonly stateStorage = new Proxy<IProcedureStateStorage>({
        isLoading: false,
        isLoaded: false,
        executed: false,
        results: null,
        error: null
    }, {
        set: (obj: IProcedureStateStorage, prop: keyof IProcedureStateStorage, value: any) => {
            obj[prop] = value;

            return true;
        }
    });

    public get url() {
        return `/nt/api/data/${this.procedureName}`;
    }

    public get streamUrl() {
        return `/nt/api/data/stream/${this.procedureName}`;
    }

    public get state() {
        return this.stateStorage;
    }

    constructor(options: IProcedureOptions) {
        this.id = options.id;
        this.procedureName = options.procedureName;
        this.useAlert = options.useAlert ?? true;
        this.useTransaction = options.useTransaction ?? true;
        this.timeout = options.timeout ?? 30;
        this.useStreamRequest = options.useStreamRequest ?? false;

        if (procedureStore.has(this.id)) {
            console.error('Procedure already exists: ', this.id);
            console.warn(`Please use getOrCreateProcedure() function instead of re-creating the same procedure:\n` +
                `import { getOrCreateProcedure } from 'o365.vue.ts';`);
            return;
        }

        procedureStore.set(this.id, this);
    }

    static getById(id: string): Procedure {
        if (!procedureStore.has(id)) {
            throw new Error('Procedure does not exist');
        }

        return procedureStore.get(id)!;
    }

    /** Executes the procedure and returns a promise of the result */
    async execute(values: T = {} as T) {
        if (this.useStreamRequest) {
            return await this.executeStream(values);
        }

        return await this.executeNormally(values);
    }

    /** Abort the last active procedure call */
    abort(pReason?: any) {
        if (this._activeAbortController) {
            this._activeAbortController.abort(pReason);
            this._activeAbortController = undefined;
        }
    }

    private async executeNormally(values: T = {} as T): Promise<any> {
        this.stateStorage.isLoading = true;

        let options: {
            Operation: string;
            ProcedureName: string;
            UseTransaction: boolean;
            Timeout: number;
            Values?: T;
        } = {
            Operation: "execute",
            ProcedureName: this.procedureName,
            UseTransaction: this.useTransaction,
            Timeout: this.timeout
        };

        this.stateStorage.isLoaded = true;
        this.emit(events.BeforeExecute, options, values);

        options["Values"] = values;

        this._activeAbortController = new AbortController();

        try {
            const response = await API.request({
                requestInfo: this.url,
                method: 'POST',
                showErrorDialog: false,
                abortSignal: this._activeAbortController?.signal,
                body: JSON.stringify(options),
                headers: new Headers({
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'X-NT-API': 'true'
                })
            });

            this.stateStorage.results = response;
            this.emit(events.Success, response);

            return response;
        } catch (reason: any) {
            this.stateStorage.error = reason;
            this.emit(events.Error, reason);

            if (this.useAlert && !(this._activeAbortController?.signal.aborted ?? false)) {
                alert(reason);
            }

            if (typeof reason === 'string') {
                reason = new Error(reason);
                reason.skipVueHandler = true;
            } else {
                reason.skipVueHandler = true;
            }

            throw reason;
        } finally {
            this.stateStorage.isLoading = false;
            this.stateStorage.isLoaded = true;
            this.stateStorage.executed = true;

            this._activeAbortController = undefined;

            this.emit(events.AfterExecute, {
                error: this.stateStorage.error,
                results: this.stateStorage.results
            });
        }
    }

    /** Executes the procedure and reads the response as a stream. The result is returned after the stream has completed, but the partial response is emitted on the PartialDataLoaded event */
    private async executeStream(values: T = {} as T): Promise<any> {
        this.stateStorage.isLoading = true;

        let options: {
            Operation: string;
            ProcedureName: string;
            UseTransaction: boolean;
            Timeout: number;
            Values?: T;
        } = {
            Operation: "execute",
            ProcedureName: this.procedureName,
            UseTransaction: this.useTransaction,
            Timeout: this.timeout
        };

        this.stateStorage.isLoaded = true;

        this.emit(events.BeforeExecute, options, values);

        options["Values"] = values;

        this._activeAbortController = new AbortController();

        try {
            const response: Response = await API.request({
                requestInfo: this.streamUrl,
                method: 'POST',
                showErrorDialog: false,
                abortSignal: this._activeAbortController?.signal,
                body: JSON.stringify(options),
                headers: new Headers({
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'X-NT-API': 'true',
                }),
                responseBodyHandler: false,
            });

            const reader = response.body?.getReader();

            if (reader === undefined) {
                throw new Error("Failed to read response");
            }

            const JsonDecoderStream = (await import('o365.modules.data.JsonDecoderStream.ts')).default;

            const decoder = JsonDecoderStream();

            const result = new Array();

            let tableIndex: number | undefined = undefined;
            let currentRowKeys: Array<string> | undefined = undefined;

            while (true) {
                const { done, value } = await reader.read();

                if (done) {
                    break;
                }

                decoder.decodeChunk(value, (item: any) => {
                    const itemKeys = Object.keys(item);

                    if (currentRowKeys === undefined || tableIndex === undefined) {
                        currentRowKeys = itemKeys;
                        tableIndex = 0;
                    } else if (currentRowKeys.length !== itemKeys.length || itemKeys.some((key) => !currentRowKeys!.includes(key))) {
                        currentRowKeys = itemKeys;
                        tableIndex++;
                    }

                    if (result.length < tableIndex + 1) {
                        result.push([]);
                    }

                    result[tableIndex].push(item);
                });

                this.stateStorage.results = result;
                this.emit(events.ChunkLoaded);
            }

            reader.releaseLock();

            this.stateStorage.results = result;
            this.emit(events.Success, result);

            return result;
        } catch (reason: any) {
            this.stateStorage.error = reason;
            this.emit(events.Error, reason);


            if (this.useAlert && !(this._activeAbortController?.signal.aborted ?? false)) {
                alert(reason);
            }

            if (typeof reason === 'string') {
                reason = new Error(reason);
                reason.skipVueHandler = true;
            } else {
                reason.skipVueHandler = true;
            }

            throw reason;
        } finally {
            this.stateStorage.isLoading = false;
            this.stateStorage.isLoaded = true;
            this.stateStorage.executed = true;

            this._activeAbortController = undefined;

            this.emit(events.AfterExecute, {
                error: this.stateStorage.error,
                results: this.stateStorage.results
            });
        }
    }

    on(event: EventTypes, listener: (...args: any[]) => any) {
        return this.eventHandler.on(event, listener);
    }

    off(event: EventTypes, listener: (...args: any[]) => any) {
        return this.eventHandler.off(event, listener);
    }

    once(event: EventTypes, listener: (...args: any[]) => any) {
        return this.eventHandler.once(event, listener);
    }

    removeAllListeners() {
        return this.eventHandler.removeAllListeners();
    }

    // TODO: emit: Add to internal object passed to base constructor instead?
    emit(event: EventTypes, ...args: Array<any>) {
        return this.eventHandler.emit(event, ...args, this);
    }
}

export { Procedure };
